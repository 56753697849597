<template>
  <section v-if="products" class="discount-catalog">
    <div class="container">
      <div class="header-block">
        <h2 class="title">{{ $t("discountCatalog") }}</h2>
        <router-link
          class="header-block__link"
          :to="{
            path: `/${$i18n.locale}/catalog?city_id=${$route.query.city_id}`,
          }"
          @click.native="gtmHandler"
        >
          {{ $t("showMore") }}
        </router-link>
      </div>
      <products-list
        v-if="products"
        :items="products"
        :from-list-name="$t('discountCatalog')"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getAllProducts } from "@/api/products";
import { gtmDataLayer } from "@/helpers";

export default {
  name: "DiscountList",
  components: {
    ProductsList: () => import("@/components/products/ProductsList.vue"),
  },

  data() {
    return {
      products: null,
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    selectedCity: {
      deep: true,
      handler() {
        getAllProducts(1, 5, this.selectedCity.id).then((response) => {
          this.products = response.data;
        });
      },
    },
  },
  mounted() {
    if (this.selectedCity) {
      getAllProducts(1, 5, this.selectedCity?.id).then((response) => {
        this.products = response.data;
      });
    }
  },
  methods: {
    gtmHandler() {
      gtmDataLayer({
        event: "view_item_list",
        type: "ecommerce",
        items: this.products,
        category: "главная",
        listName: this.$t("discountCatalog"),
      });
    },
  },
};
</script>
